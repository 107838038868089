import React from 'react';
import { get, orderBy, sortBy } from "lodash";
import { calculateLeaseLogic_RS as calculateLeaseLogic, calculateEstimatedTotal, shouldGetPaymentAmount, getPaymentAmount } from "../RSheets/RSheetsLogic";
import { clone, commatize, currencyToNumber, formatDate2, formatDollar, parseFloatString } from "../../shared/utility";
import moment from "moment";
import { is } from 'redux-saga/utils';

export const calculateMonths = (date1, date2, rent) => {
    if (!date1 || !date2) return 0;

    const termRent = calculateTermRent(date1, date2, rent);

    return parseFloat(parseFloat(termRent / rent).toFixed(2));
};

export const calculateTermRent = (start, end, rent) => {
    const calcs = calculateLeaseLogic({
        date1: start,
        date2: end,
        value: rent,
    });
    const total = calcs.reduce((prev, curr) => curr.calcRounded + prev, 0);
    return total;
};

/**
 * Somehow the calculateLeaseLogic in RSheetsLogic is not accurate for February, so I make this new calculation
 */
export const calculateLeaseLogic2 = (range, isEstimated, isActualDays) => {
    if (isEstimated) {
        const monthStart = parseInt(get(range, "month1", "").split(" ")[1]);
        const dayStart = parseInt(get(range, "day1", "").split(" ")[1]);

        const monthEnd = parseInt(get(range, "month2", "").split(" ")[1]);
        const dayEnd = parseInt(get(range, "day2", "").split(" ")[1]);

        let value = range.value;

        if (range.abated) {
            value = parseFloat(value) - parseFloat(get(range, 'abated.value', 0));
        }

        let totalMonths = (monthEnd * 30 + dayEnd - monthStart * 30 - dayStart + 1) / 30;

        return {
            months: totalMonths,
            termRent: parseFloat(value) * totalMonths,
        }
    } else {
        const start = moment(range.date1).startOf('day');
        const end = moment(range.date2).startOf('day');
        let value = range.value;

        if (range.abated) {
            value = parseFloat(value) - parseFloat(get(range, 'abated.value', 0));
        }

        let totalMonths = 0;

		if (start.month() === end.month()) {
			// const daysInMonth = start.daysInMonth();
			const daysInMonth = isActualDays ? start.daysInMonth() : 30;
			totalMonths = (end.date() - start.date() + 1) / daysInMonth;
		} else {
			let endOfMonth = moment(start).endOf('month').startOf('day')
			while (endOfMonth.isBefore(end)) {
				if (endOfMonth.month() === start.month()) {
					// const daysInMonth = start.daysInMonth();
					const daysInMonth = isActualDays ? start.daysInMonth() : 30;
					totalMonths += (daysInMonth - start.date() + 1) / daysInMonth;
				} else {
					totalMonths += 1;
				}
				endOfMonth.add(1, 'month').endOf('month').startOf('day');;
			}
	
			if (endOfMonth.isSame(end)) {
				totalMonths += 1
			} else {
				// const daysInMonth = end.daysInMonth();
				const daysInMonth = isActualDays ? end.daysInMonth() : 30;
				totalMonths += end.date() / daysInMonth;
			}
		}

        return {
            months: totalMonths,
            termRent: parseFloat(value) * totalMonths,
        }
    }
}

export const calculateCommission = (start, end, rent, rate) => {
    if (!rate || !rent) return 0;
    const termRent = calculateTermRent(start, end, rent);
    return (parseFloat(rate) / 100) * termRent;
};

export const calculateTermsTotal = (ranges) => {
    let totalMonths = 0;
    let totalTermRent = 0;
    let totalCommission = 0;

    if (!ranges || !Array.isArray(ranges))
        return {
            totalMonths,
            totalTermRent,
            totalCommission,
        };

    ranges.forEach((r) => {
	// const months = calculateMonths(range.date1, range.date2, range.value);
		const range = clone(r)
        let rent = get(range, "value", 0);
        const rate = get(range, "commissionRate", 0) || 0;
        if (range.abated) {
            range.value = -rent;
        }
        const {termRent, months} = calculateLeaseLogic2(range, false);
        const commission = parseFloat((termRent * parseFloat(rate)) / 100);

        if (typeof months === "number") {
            if (!range.abated) {
                totalMonths += months;
            }
        }
        if (typeof termRent === "number") {
            totalTermRent += termRent;
        }
        if (typeof commission === "number") {
            totalCommission += commission;
        }
    });
    return {
        totalMonths,
        totalTermRent,
        totalCommission,
    };
};

export const getRsData = (rsData, field, options = {}) => {
    if (!options.isArray) return get(rsData, field, "") || "";
	return get(rsData, field, []) || [];
};

export const calculateBalanceForward = (invoice_index, invoicePayments, rsData, totalAmountThisInvoice) => {
	console.log('calculating balance forward', invoice_index, invoicePayments, rsData, totalAmountThisInvoice)
	const balanceForward = calculateBalanceForwardTotal(invoice_index, invoicePayments, rsData);
	return balanceForward + totalAmountThisInvoice;
}

export const calculateBalanceForwardTotal = (invoice_index, invoicePayments, rsData) => {
	console.log('calculate balance forward total', invoice_index, invoicePayments, rsData);
	let balanceForward = 0;
	if (rsData.invoices) {
		rsData.invoices.forEach((inv,i) => {
			if (i < invoice_index) {
				// get balances before this invoice
				const formData = clone(inv.data);
				formData.rsData = clone(rsData);
				const current_invoice_balance = getBalanceDue(formData);
				balanceForward += current_invoice_balance;
				console.log('balance forward + ', i, current_invoice_balance)
				// minus paid invoices
				let paid = 0;
				if (invoicePayments) {
					invoicePayments.forEach(invp => {
						if (invp.invoice_id === inv.id) {
							paid += parseFloatString(invp.payment_amount);
						}
					})
				}
				balanceForward -= paid;
				console.log('balance forward - ', i, paid);
			}
		});
	}
	return balanceForward;
}

export const getRemainingBalance = (transCommission, invoice_index, invoicePaymentsObj, invoices = []) => {
	console.log('getting remaining balance', transCommission, invoice_index, invoicePaymentsObj);
    let paid = 0;
    // if (x) paid = parseFloat(x);
	let invoice_ids_before_and_current_invoice = [];
	invoices.forEach((inv,i) => {
		if (i <= invoice_index) {
			invoice_ids_before_and_current_invoice.push(inv.id);
		}
	})
	console.log('current and before invoices', invoice_ids_before_and_current_invoice);
	if (invoicePaymentsObj) {
		if (invoicePaymentsObj.invoicePayments) {
			invoicePaymentsObj.invoicePayments.forEach(ip => {
				if (invoice_ids_before_and_current_invoice.indexOf(ip.invoice_id) !== -1) {
					const addUp = parseFloatString(ip.payment_amount);
					console.log('inv payments added', ip, addUp);
					paid += addUp;
				}
			})
		}
	}
	console.log('getting remaining balance ->', paid);
    return {
        paid,
        remain: parseFloat(transCommission) - paid,
    };
};

/**
 * Get Total Paid from Invoice
 * @param {object} invoiceData - Invoice's data, not invoice instance
 */
export const getTotalPaid = (invoiceData) => {
    const payments = get(invoiceData, "payments", []);
    const total = payments.reduce((prev, curr) => {
        return parseFloat(currencyToNumber(curr.amount)) + prev;
    }, 0);
    return total;
};

/**
 * Calculate terms for invoice table
 */
export const calculateInvoiceTerm = (ranges, abatedRanges, isEstimated = false) => {
	ranges = sortBy(ranges, (item) => new Date(item.date1))
	abatedRanges = sortBy(abatedRanges, (item) => new Date(item.date1))
	
    const list = [];
	if (isEstimated) {
		for (const range of ranges) {
			const currentRanges = [];
			for (const abatedRange of abatedRanges) {
				let abated_start = { month: abatedRange.month1, day: abatedRange.day1 }
				let abated_end = { month: abatedRange.month2, day: abatedRange.day2 }
				if (isInRange(range, abated_start, isEstimated) && isInRange(range, abated_end, isEstimated)) {
					// Before abated
					const rangeToCheck = {
						...range,
						month1: range.month1,
						day1: range.day1,
						month2: add(abated_start, -1, 'day').month,
						day2: add(abated_start, -1, 'day').day,
					};
					maybeAddRange(currentRanges, rangeToCheck, isEstimated);

					// During abated
					currentRanges.push({
						...range,
						month1: abatedRange.month1,
						day1: abatedRange.day1,
						month2: abatedRange.month2,
						day2: abatedRange.day2,
						abated: {
							usePercentage: abatedRange.usePercentage,
							percent: abatedRange.percent,
							value: abatedRange.value,
						},
					});

					// After abated
					const rangeToCheck2 = {
						...range,
						month1: add(abated_end, 1, 'day').month,
						day1: add(abated_end, 1, 'day').day,
						month2: range.month2,
						day2: range.day2,

					};
					maybeAddRange(currentRanges, rangeToCheck2, isEstimated);
				} else if (isInRange(range, abated_start, isEstimated) && !isInRange(range, abated_end, isEstimated)) {
					// Before abated
					const rangeToCheck = {
						...range,
						month1: range.month1,
						day1: range.day1,
						month2: add(abated_start, -1, 'day').month,
						day2: add(abated_start, -1, 'day').day,
					};
					maybeAddRange(currentRanges, rangeToCheck);

					// During abated
					currentRanges.push({
						...range,
						month1: abatedRange.month1,
						day1: abatedRange.day1,
						month2: abatedRange.month2,
						day2: abatedRange.day2,
						abated: {
							usePercentage: abatedRange.usePercentage,
							percent: abatedRange.percent,
							value: abatedRange.value,
						},
					});
				} else if (!isInRange(range, abated_start, isEstimated) && isInRange(range, abated_start, isEstimated)) {
					// During abated
					currentRanges.push({
						...range,
						month1: abatedRange.month1,
						day1: abatedRange.day1,
						month2: abatedRange.month2,
						day2: abatedRange.day2,
						abated: {
							usePercentage: abatedRange.usePercentage,
							percent: abatedRange.percent,
							value: abatedRange.value,
						},
					});

					// After abated
					const rangeToCheck2 = {
						...range,
						month1: add(abated_end, 1, 'day').month,
						day1: add(abated_end, 1, 'day').day,
						month2: range.month2,
						day2: range.day2,

					};
					maybeAddRange(currentRanges, rangeToCheck2, isEstimated);
				} else if (isInRange(abatedRange, { month: range.month1, day: range.day1 }, isEstimated) && isInRange(abatedRange, { month: range.month2, day: range.day2 }, isEstimated)) {
					// During abated
					currentRanges.push({
						...range,
						month1: range.month1,
						day1: range.day1,
						month2: range.month2,
						day2: range.day2,
						abated: {
							usePercentage: abatedRange.usePercentage,
							percent: abatedRange.percent,
							value: abatedRange.value,
						},
					});
				}
			}
			const newRanges = adjustRanges(currentRanges, isEstimated);
			list.push(...newRanges);
		}
	} else {
		for (const range of ranges) {
			let currentRanges = [];
			for (const abatedRange of abatedRanges) {

				if (isInRange(range, abatedRange.date1) && isInRange(range, abatedRange.date2)) {
					// Before abated
					const rangeToCheck = {
						...range,
						date1: range.date1,
						date2: formatDate2(moment(abatedRange.date1).subtract(1, "days")),
					};
					maybeAddRange(currentRanges, rangeToCheck);

					// During abated
					const abated = {
						...range,
						date1: abatedRange.date1,
						date2: abatedRange.date2,
						abated: {
							usePercentage: abatedRange.usePercentage,
							percent: abatedRange.percent,
							value: abatedRange.value,
						},
					}
					currentRanges.push(abated);
					currentRanges = maybeRemoveRange(currentRanges, abated)

					// After abated
					const rangeToCheck2 = {
						...range,
						date1: formatDate2(moment(abatedRange.date2).add(1, "days")),
						date2: range.date2,
					};
					maybeAddRange(currentRanges, rangeToCheck2);
				} else if (isInRange(range, abatedRange.date1) && !isInRange(range, abatedRange.date2)) {
					// Before abated
					const rangeToCheck = {
						...range,
						date1: range.date1,
						date2: formatDate2(moment(abatedRange.date1).subtract(1, "days")),
					};
					maybeAddRange(currentRanges, rangeToCheck);

					// During abated
					const abated = {
						...range,
						date1: abatedRange.date1,
						date2: range.date2,
						abated: {
							usePercentage: abatedRange.usePercentage,
							percent: abatedRange.percent,
							value: abatedRange.value,
						},
					}
					currentRanges.push(abated);
					currentRanges = maybeRemoveRange(currentRanges, abated)
				} else if (!isInRange(range, abatedRange.date1) && isInRange(range, abatedRange.date2)) {
					// During abated
					const abated = {
						...range,
						date1: range.date1,
						date2: abatedRange.date2,
						abated: {
							usePercentage: abatedRange.usePercentage,
							percent: abatedRange.percent,
							value: abatedRange.value,
						},
					}
					currentRanges.push(abated);
					currentRanges = maybeRemoveRange(currentRanges, abated)

					// After abated
					const rangeToCheck = {
						...range,
						date1: formatDate2(moment(abatedRange.date2).add(1, "days")),
						date2: range.date2,
					};
					maybeAddRange(currentRanges, rangeToCheck);
				} else if (isInRange(abatedRange, range.date1) && isInRange(abatedRange, range.date2)) {
					// During abated
					const abated = {
						...range,
						date1: range.date1,
						date2: range.date2,
						abated: {
							usePercentage: abatedRange.usePercentage,
							percent: abatedRange.percent,
							value: abatedRange.value,
						},
					}
					currentRanges.push(abated);
					currentRanges = maybeRemoveRange(currentRanges, abated)
				} else if (moment(range.date1).isAfter(abatedRange.date2) || moment(range.date2).isBefore(abatedRange.date1)) {
					maybeAddRange(currentRanges, range)
				}
			}
			const newRanges = adjustRanges(currentRanges);
			list.push(...newRanges);
		}
	}

	const newList = adjustRanges([...list], isEstimated);

    return newList;
};

/**
 * Check if the date is fall between the range
 */
export const isInRange = (range, date, isEstimated = false) => {
	if (isEstimated) {
		const monthStart = parseInt(get(range, "month1", "").split(" ")[1]);
		const monthEnd 	 = parseInt(get(range, "month2", "").split(" ")[1]);
		const dayStart   = parseInt(get(range, "day1", "").split(" ")[1]);
		const dayEnd 	 = parseInt(get(range, "day2", "").split(" ")[1]);
		const checkMonth = parseInt(date.month.split(" ")[1]);
		const checkDay = parseInt(date.day.split(" ")[1]);

		let isBetween = true;
		if (checkMonth === monthStart && checkDay < dayStart) {
			isBetween = false;
		} else if (checkMonth === monthEnd && checkDay > dayEnd) {
			isBetween = false;
		} else if (checkMonth < monthStart) {
			isBetween = false;
		} else if (checkMonth > monthEnd) {
			isBetween = false;
		}
		return isBetween;
	} else {
		const start = moment(range.date1);
		const end = moment(range.date2);
		const checkDate = moment(date);
		return checkDate.isBetween(start, end, null, []);
	}
};


export const isSameOrBefore = (checkDate, date) => {
	const checkMonth = parseInt(checkDate.month.split(" ")[1]);
	const checkDay = parseInt(checkDate.day.split(" ")[1]);
	const month = parseInt(date.month.split(" ")[1]);
	const day = parseInt(date.day.split(" ")[1]);

	let isSameOrBefore = false;
	if (checkMonth === month && checkDay <= day) {
		isSameOrBefore = true;
	} else if (checkMonth < month) {
		isSameOrBefore = true;
	}
	return isSameOrBefore;
};
export const isSameOrAfter = (checkDate, date) => {
	const checkMonth = parseInt(checkDate.month.split(" ")[1]);
	const checkDay = parseInt(checkDate.day.split(" ")[1]);
	const month = parseInt(date.month.split(" ")[1]);
	const day = parseInt(date.day.split(" ")[1]);

	let isSameOrAfter = false;
	if (checkMonth === month && checkDay >= day) {
		isSameOrAfter = true;
	} else if (checkMonth > month) {
		isSameOrAfter = true;
	}
	return isSameOrAfter;
};
export const add = (date, value, unit) => {
	let month = parseInt(date.month.split(" ")[1]);
	let day = parseInt(date.day.split(" ")[1]);

	if (unit === 'day') {
		day += value;
		if (day <= 0) {
			day += 30;
			month -= 1;
		} else if (day > 30) {
			day -= 30;
			month += 1;
		}
	} else if (unit === 'month') {
		month += value;
	}
	return {
		month: `Month ${month}`,
		day: `Day ${day}`,
	}
};
/**
 * Check if should add a new calculated range
*/
export const maybeAddRange = (ranges, newRange, isEstimated = false) => {
    let shouldAdd = true;

	if (isEstimated) {
		for (const index in ranges) {
			const range = ranges[index];
			const isSameOrBefore_1 = isSameOrBefore({ month: newRange.month1, day: newRange.day1 }, { month: range.month1, day: range.day1 });
			const isSameOrAfter_1 = isSameOrAfter({ month: newRange.month1, day: newRange.day1 }, { month: range.month1, day: range.day1 });
			if (isSameOrBefore_1 && isSameOrAfter_1) {
				const isSameOrAfter_2 = isSameOrAfter({ month: newRange.month2, day: newRange.day2 }, { month: range.month1, day: range.day1 })
				if (isSameOrAfter_2) shouldAdd = false;
			}
		}
	} else {
		if (moment(newRange.date2).isSameOrBefore(newRange.date1)) shouldAdd = false

		for (const index in ranges) {
			const range = ranges[index];
			if (newRange.date1 === range.date1) {
				if (moment(newRange.date2).isSameOrAfter(range.date1)) {
					shouldAdd = false;
				}
			}
		}
	}
    if (shouldAdd) ranges.push(newRange);
	return;
};

/**
 * Remove range that is not abated if necessary
 */
export const maybeRemoveRange = (ranges, abatedRange) => {
	let newRanges = clone(ranges);
	newRanges = newRanges.filter(range => {
		if (!range.abated && range.date1 === abatedRange.date1) {
			return false
		}
		return true
	})
	return newRanges
}	

/**
 * Re-adjust the date in the ranges
 */
export const adjustRanges = (ranges, isEstimated = false) => {
    const newRanges = clone(ranges);
    for (const i in ranges) {
        const range1 = ranges[i];
        for (const j in ranges) {
            const range2 = ranges[j];
            if (i !== j) {
                if (!range1.abated && range2.abated) {
					if (isEstimated) {
						const isSameOrBefore_1 = isSameOrBefore({ month: range1.month1, day: range1.day1 }, { month: range2.month1, day: range2.day1 });
						const isSameOrAfter_1 = isSameOrAfter({ month: range1.month2, day: range1.day2 }, { month: range2.month1, day: range2.day1 });
						if (isSameOrBefore_1 && isSameOrAfter_1) {
							const { month, day } = add({ month: range2.month1, day: range2.day1 }, -1, 'day')
							newRanges[i].month2 = month;
							newRanges[i].day2 = day;
						}
					} else {
						if (moment(range1.date1).isSameOrBefore(moment(range2.date1)) && moment(range1.date2).isSameOrAfter(range2.date1)) {
							newRanges[i].date2 = formatDate2(moment(range2.date1).subtract(1, "days"));
						}
					}
                }
            }
        }
    }
    return newRanges;
};

/**
 * Calculate Months, Term Rent, and Commission for a range
 */
export const getRangeDetail = (range, isEstimated = false, isActualDays = false) => {
    const rate = range.commissionRate;
    const { months, termRent } = calculateLeaseLogic2(range, isEstimated, isActualDays);
    let termLabel = '';
    if (isEstimated) {
        const monthStart = range.month1;
        const dayStart = range.day1;
        const monthEnd = range.month2;
        const dayEnd = range.day2;

        termLabel = `${monthStart} ${dayStart} - ${monthEnd} ${dayEnd}`;
    } else {
        const start = range.date1;
        const end = range.date2;
        termLabel = `${moment(start).format("LL")} - ${moment(end).format("LL")}`;
    }
    const commission = rate * termRent / 100;

    return {
        months,
        termRent,
        commission,
        termLabel,
    }
}

export const renderEstimatedTermTable = (totalRanges, ranges, abatedRanges) => {
    return totalRanges.map((range) => {
		const monthStart = parseInt(get(range, "month1", "").split(" ")[1]);
		const monthEnd 	 = parseInt(get(range, "month2", "").split(" ")[1]);
		const dayStart   = parseInt(get(range, "day1", "").split(" ")[1]);
		const dayEnd 	 = parseInt(get(range, "day2", "").split(" ")[1]);
		let rent 		 = parseFloat(get(range, "value", 0));
		let rate 		 = get(range, "commissionRate", "");
		let term;
		if (range.abated) {
			rent = -rent;
			term = `Month ${monthStart} Day ${dayStart} - Month ${monthEnd} Day ${dayEnd} (Abated)`;
		} else {
			term = `Month ${monthStart} Day ${dayStart} - Month ${monthEnd} Day ${dayEnd}`;
		}
		const termTotal = calculateEstimatedTotal(range);

		return (
			<TermTableRow
				termLabel={term}
				rent={formatDollar(rent)}
				months={commatize(termTotal.totalMonths, 2)}
				termRent={formatDollar(termTotal.totalTermRent)}
				rate={commatize(rate, 2)}
				commission={formatDollar(termTotal.totalCommission)}
			/>
		)
	});
};

const TermTableRow = (props) => {
	return (
		<>
			<tr className="fs-roboto">
				<td className="col-25" style={{ fontSize: "10px", padding: "2px 7px" }}>
					{props.termLabel}
				</td>
				<td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
					{props.rent}
				</td>
				<td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
					{props.months}
				</td>
				<td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
					{props.termRent}
				</td>
				<td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
					{props.rate} %
				</td>
				<td className="col-14 textRight" style={{ fontSize: "10px", padding: "2px 0x" }}>
					{props.commission}
				</td>
				<td className="col-5"></td>
			</tr>
		</>
	);
}

export const renderSaleInvoiceDetails = (rsType, rsData) => {
	console.log('rsType rsData', rsType, rsData);
	if (rsType === 4) {
		// RS is Sale
		if (rsData.sale_sale_commission) {
			const num = parseFloat(rsData.sale_sale_commission.replace(/[$,]/g, ""));
			return <tr style={{ paddingTop: "10px" }}>
				<td className="col-80" style={{ padding: "5px 7px 0" }}>
					<strong>Sale Commission</strong>
				</td>
				<td className="col-20 textRight fs-roboto" style={{ padding: "5px 7px 0px" }}>
					{formatDollar(num)}
				</td>
			</tr>
		}
	}
	return null;
}

export const renderSaleInvoiceDetailsHTML = (rsType, rsData) => {
	console.log('rsType rsData', rsType, rsData);
	if (rsType === 4) {
		// RS is Sale
		if (rsData.sale_sale_commission) {
			const num = parseFloat(rsData.sale_sale_commission.replace(/[$,]/g, ""));
			return `<tr style="padding-top: 10px">
						<td class="col-80" style="padding: 5px 7px 0">
							<strong>Sale Commission</strong>
						</td>
						<td class="col-20 textRight fs-roboto" style="padding: 5px 7px 0px">
							${formatDollar(num)}
						</td>
					</tr>`
		}
	}
	return '';
}

export const getTotalInvoicePayment = (data) => {
	let total = 0;
	if (data.invoices) {
		data.invoices.forEach((di,i) => {
			di.data.entries.forEach(dde => {
				let skip = false;
				if (i > 0) {
					if (dde.display_only) {
						skip = true;
					}
				}
				if (!skip) {
					let toBeAdded = parseFloatString(dde.value);
					const shouldGetPayment = shouldGetPaymentAmount(dde.option);
					if (shouldGetPayment) {
						toBeAdded = getPaymentAmount(data, dde.option);
					}
					total += toBeAdded;
					console.log('adding to invoice total - entries', total, toBeAdded);
				}
			})
			di.data.line_items.forEach(ddl => {
				total += parseFloatString(ddl.amount);
				console.log('adding to invoice total - line items', total, parseFloatString(ddl.amount));
			})
		})
	}
	return total;
}

export const getTotalInvoicePaymentWithoutLineItems = (data) => {
	let total = 0;
	if (data.invoices) {
		data.invoices.forEach((di,i) => {
			di.data.entries.forEach(dde => {
				console.log('dde', dde);
				let skip = false;
				if (i > 0) {
					if (dde.display_only) {
						skip = true;
					}
				}
				if (!skip) {
					let toBeAdded = parseFloatString(dde.value);
					const shouldGetPayment = shouldGetPaymentAmount(dde.option);
					if (shouldGetPayment) {
						toBeAdded = getPaymentAmount(data, dde.option);
					}
					total += toBeAdded;
					console.log('dde adding to invoice total - entries', total, toBeAdded);
				}
			})
		})
	}
	return total;
}

export const getBalanceDue = (formData, transactionCommission) => {
	console.log('getBalanceDue', formData, transactionCommission)
	let balanceDue = 0;
	const calculationType = 1;
	if (calculationType === 1) {
		// NEW
		// minus Payment Schedule
		if (formData) {
			if (formData.entries) {
				formData.entries.forEach((dde,i) => {
					let toBeSubtracted = parseFloatString(dde.value);
					const shouldGetPayment = shouldGetPaymentAmount(dde.option);
					if (shouldGetPayment) {
						toBeSubtracted = getPaymentAmount(formData.rsData, dde.option);
					}
					console.log('getBalance Due to be subtracted', toBeSubtracted);
					if (i === 0) {
						// only first one is always subtracted
						balanceDue += toBeSubtracted;
					} else {
						let skip = false;
						if (i > 0) {
							if (dde.display_only) {
								skip = true;
							}
						}
						if (!skip) {
							balanceDue += toBeSubtracted;
						}
					}
				})
			}
		}
		formData.line_items.forEach(li => {
			balanceDue += li.amount;
		})
	} else {
		// PREVIOUS
		// get Transaction Commission
		balanceDue += transactionCommission;
		// minus Payment Schedule
		if (formData) {
			if (formData.entries) {
				formData.entries.forEach(dde => {
					let toBeSubtracted = parseFloatString(dde.value);
					const shouldGetPayment = shouldGetPaymentAmount(dde.option);
					if (shouldGetPayment) {
						toBeSubtracted = getPaymentAmount(formData.rsData, dde.option);
					}
					console.log('getBalance Due to be subtracted', toBeSubtracted);
					balanceDue -= toBeSubtracted;
				})
			}
		}
		formData.line_items.forEach(li => {
			balanceDue += li.amount;
		})
	}
	// plus Line Items
	return balanceDue;
}

export const getInvoiceFormData = (rsData, invoice_id) => {
    // gets data saved in RS that contains all invoices
    let invoice = null;
    if (rsData.invoices) {
        rsData.invoices.forEach(inv => {
            if (inv.id === invoice_id) {
                invoice = inv;
            }
        })
    }
    return invoice;
}

export const getInvoiceFullData = (data) => {
	// data = backend "invoices" table entry
	const rsData = data.json_data;
	const invoiceData = data.data;
	const invoice_id = data.id;
	const rsInvoiceData = clone(getInvoiceFormData(rsData, invoice_id));
	rsInvoiceData.invoice_num = data.invoice_num; // retrieve from invoice entry only data
	rsInvoiceData.date = invoiceData.date; // retrieve from invoice entry only data
	rsInvoiceData.rsData = clone(rsData);
	rsInvoiceData.line_items = get(rsInvoiceData, 'data.line_items', []);
	rsInvoiceData.entries = get(rsInvoiceData, 'data.entries', []);
	return rsInvoiceData;
}

export const getInvoiceIndex = (rsData, invoice_id) => {
    let index = 0;
    if (rsData) {
        if (rsData.invoices) {
            rsData.invoices.forEach((rsi,i) => {
                if (rsi.id === invoice_id) {
                    index = i;
                }
            })
        }
    }
    return index;
}
