import React, { useEffect, useState } from "react";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import { every, flattenDeep, some } from "lodash";
import Button from "../../../components/CustomButtons/Button";
import CustomInput from "../../../components/CustomInput/CustomInputValidate";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { clone, get2DecimalRound } from "../../../shared/utility";
import { getPaymentAmount, shouldGetPaymentAmount } from '../RSheetsLogic';
import { TextField } from "@material-ui/core";
import TextAreaField from "../../../components/CustomTextArea/CustomTextArea";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";

const PaymentScheduleForm = (props) => {
    const {
        closeEditMode,
        data,
        field,
        handleAddNewPayment,
        handleUpdatePayment,
        totalPayment,
        total,
        paymentScheduleOptions,
        pm_index,
        iv_index,
        editMode,
        initValues,
        invoiceList,
        updateDataObj
    } = props;

    const [dat, setData] = useState({
        value: "",
        option: "",
        misc: "",
        notes: "",
        display_only: true
    });

    const {option} = dat;

    const shouldGetPayment = shouldGetPaymentAmount(option)
    const paymentValue = getPaymentAmount(data, option)

    useEffect(() => {
        if (initValues) {
            const newData = clone(dat);
            newData.value = initValues.value || "";
            newData.option = initValues.option || "";
            newData.notes = initValues.notes || "";
            newData.display_only = initValues.display_only || false;
            setData(newData);
        }
    }, [initValues]);

    const checkValidPayment = () => {
        console.log('checking valid payment 1', totalPayment);
        let newTotal = totalPayment;
        if (editMode && initValues && initValues.value) {
            newTotal = newTotal - parseFloat(initValues.value.replace(/[$,]/g, ""));
        }
        if (dat.value) {
            newTotal += parseFloat(dat.value.replace(/[$,]/g, ""));
        }
        console.log('checking valid payment 2', newTotal, parseFloat(total.toFixed(2)));
        return newTotal <= parseFloat(total.toFixed(2));
    };

    const checkValidForm = () => {
        // OLD - checks totals
        // if (shouldGetPayment) return checkValidPayment()
        // return checkValidPayment() && dat.option && dat.value;

        // NEW - ignores checks because some are just for display only 
        if (dat.option && dat.value) return true;
        if (dat.option) {
            if (dat.option.indexOf('Input Box To Specify') === -1) {
                return true;
            }
        }
        return false;
    }

    const getValidOptions = () => {
        let currentOptions = [];
        if (editMode && initValues && initValues.option) {
            console.log('invoice', invoiceList);
            const newInvoiceList = clone(invoiceList);
            newInvoiceList.splice(pm_index, 1);
            currentOptions = flattenDeep(newInvoiceList).map((pm) => pm.option);
        } else {
            currentOptions = flattenDeep(invoiceList).map((pm) => pm.option);
        }

        let validOptions = paymentScheduleOptions;

        // Full, First Half, or Second Half - CANNOT have FULL
        if (
            some(currentOptions, (item) => item.toLowerCase().includes("full")) ||
            some(currentOptions, (item) => item.toLowerCase().includes("first half")) ||
            some(currentOptions, (item) => item.toLowerCase().includes("second half"))
        ) {
            validOptions = validOptions.filter((item) => !item.toLowerCase().includes("full"));
        }

        // Due in full - CANNOT have a first half or second half
        if (some(currentOptions, (item) => item.toLowerCase().includes("full"))) {
            validOptions = validOptions.filter(
                (item) => !item.toLowerCase().includes("first half") && !item.toLowerCase().includes("second half")
            );
        }

        // First half on an invoice - CANNOT have another first half
        if (some(currentOptions, (item) => item.toLowerCase().includes("first half"))) {
            validOptions = validOptions.filter((item) => !item.toLowerCase().includes("first half"));
        }

        // CANNOT have a second half without the first half
        if (every(currentOptions, (item) => !item.toLowerCase().includes("first half"))) {
            validOptions = validOptions.filter((item) => !item.toLowerCase().includes("second half"));
        }

        // Second half on an invoice - CANNOT have another second half
        if (some(currentOptions, (item) => item.toLowerCase().includes("second half"))) {
            validOptions = validOptions.filter((item) => !item.toLowerCase().includes("second half"));
        }

        return validOptions;
    };

    const update = (field, value) => {
        const n = clone(dat);
        n[field] = value;
        setData(n);
    };

    return (
        <div className="boxed">
            <GridContainer>
                {(pm_index === 0 || pm_index) && (
                    <GridItem xs={12} sm={1} md={1}>
                        <div style={{ marginTop: 33 }}>{`#${pm_index + 1}`}</div>
                    </GridItem>
                )}

                <GridItem xs={12} sm={3} md={3}>
                    {dat.option && <CustomInput
                        label="Enter Payment Value"
                        value={shouldGetPayment ? paymentValue : dat.value}
                        onChange={(e) => {
                            update("value", e.target.value);
                        }}
                        readonly={shouldGetPayment}
                        isDollar
                    />}
                    {/* {!checkValidPayment() && <p className="red">Cannot exceed total commission</p>} */}
                </GridItem>
                <GridItem xs={12} sm={8} md={6}>
                    {!props.editMode && <CustomSelect
                        label={"Select Option"}
                        options={!props.editMode ? props.currentOptions : getValidOptions()}
                        default={dat.option}
                        choose={(e, n) => {
                            update("option", e);
                        }}
                    />}
                    {props.editMode && <CustomInput 
                        label="Select Option"
                        value={dat.option}
                        disabled={true}
                        onChange={() => { }}
                        readonly={true}
                    />}
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                        {/* Only first entry will always be shown/calculated  */}
                        {pm_index !== 0 && <CustomCheckbox 
                            checked={dat.display_only ? true : false}
                            onChange={() => {
                                update("display_only", !dat.display_only)
                            }}
                            label={<div>Display Only?</div>}
                        />}
                </GridItem>
            </GridContainer>
            {dat.option === "xiii. Other -- Input box to specify" && (
                <GridContainer>
                    <GridItem xs={12} sm={5} md={5}></GridItem>
                    <GridItem xs={12} sm={7} md={7}>
                        <div>
                            <CustomInput
                                label="Enter Description For Other Payment"
                                value={dat.misc}
                                onChange={(e) => {
                                    update("misc", e.target.value);
                                }}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}></GridItem>
                </GridContainer>
            )}
            <GridContainer>
                <GridItem xs={12} sm={5} md={5}>
                    <TextAreaField 
                        label="Notes"
                        value={dat.notes ? dat.notes : ''}
                        onChange={(e) => {
                            update("notes", e.target.value)
                        }}
                    />
                </GridItem>
            </GridContainer>
            {editMode ? (
            <>
                <Button
                    className="mr-20"
                    color="primary"
                    disabled={!checkValidForm()}
                    onClick={() => {
                        handleUpdatePayment(pm_index, dat);
                        closeEditMode();
                    }}
                >
                    <i className="fa fa-edit"></i> UPDATE
                </Button>
                <Button
                    color="white"
                    onClick={() => {
                        closeEditMode();
                    }}
                >
                    CANCEL
                </Button>
            </>
            ) : (
                <Button
                    color="primary"
                    disabled={!checkValidForm()}
                    onClick={() => {
                        if (checkValidForm()) {
                            handleAddNewPayment({
                                    value: dat.value,
                                    option: dat.option,
                                    misc: dat.misc,
                                    notes: dat.notes,
                                    display_only: dat.display_only
                            });
                            setData({
                                value: "",
                                date: "",
                                option: "",
                                misc: "",
                                notes: "",
                                display_only: false
                            });
                            if (props.setOpenedAddPaymentSchedule) {
                                props.setOpenedAddPaymentSchedule(false);
                            }
                        }
                    }}
                >
                    <i className="fas fa-plus"></i> ADD
                </Button>
            )}
        </div>
    );
};

export default PaymentScheduleForm;
